var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',[_c('h6',{staticClass:"text-primary required"},[_vm._v("Chọn giấy")]),_c('validation-provider',{attrs:{"name":"catalogueItem","rules":"required","mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var validate = ref.validate;
return [_c('v-select',{attrs:{"id":"catalogueItem","label":"name","options":_vm.paperOptions,"reduce":function (paper) { return paper.id; }},on:{"close":function () {
                validate();
                _vm.setPolymeCoverTypes(true);
              }},model:{value:(_vm.catalogueOrderDetail.catalogueItemId),callback:function ($$v) {_vm.$set(_vm.catalogueOrderDetail, "catalogueItemId", $$v)},expression:"catalogueOrderDetail.catalogueItemId"}}),(errors.length > 0)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}])})],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',[_c('h6',{staticClass:"text-primary"},[_vm._v("Gia công")]),_c('v-select',{attrs:{"id":"ProcessTypelbl","label":"name","options":_vm.polymeCoverTypes,"reduce":function (polymeCoverType) { return polymeCoverType.id; }},on:{"close":function () {
              _vm.setPolymeCoverType();
            }},model:{value:(_vm.catalogueOrderDetail.polymeCoverType),callback:function ($$v) {_vm.$set(_vm.catalogueOrderDetail, "polymeCoverType", $$v)},expression:"catalogueOrderDetail.polymeCoverType"}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"sm":"6"}},[_c('b-row',[_c('b-col',{attrs:{"sm":"4"}},[_c('b-form-group',[_c('h6',{staticClass:"text-primary required"},[_vm._v("Kích thước")]),_c('validation-provider',{attrs:{"name":"size","rules":"required","mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
            var validate = ref.validate;
return [_c('v-select',{attrs:{"id":"catalogueSize","label":"name","options":_vm.catalogueSizes,"reduce":function (size) { return size.name; }},on:{"close":function () {
                    validate();
                    _vm.onSizeChange();
                  }},model:{value:(_vm.catalogueOrderDetail.size),callback:function ($$v) {_vm.$set(_vm.catalogueOrderDetail, "size", $$v)},expression:"catalogueOrderDetail.size"}}),(errors.length > 0)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}])})],1)],1),_c('b-col',{attrs:{"sm":"4"}},[_c('b-form-group',[_c('h6',{staticClass:"text-primary required"},[_vm._v("Số trang")]),_c('validation-provider',{attrs:{"name":"pageTotal","rules":"required","mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"pageTotal","taggable":"","label":"name","options":_vm.pageOptions},on:{"input":function($event){return _vm.onPriceChange(false)}},model:{value:(_vm.catalogueOrderDetail.totalPage),callback:function ($$v) {_vm.$set(_vm.catalogueOrderDetail, "totalPage", $$v)},expression:"catalogueOrderDetail.totalPage"}}),(errors.length > 0)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}])})],1)],1),_c('b-col',{attrs:{"sm":"4"}},[_c('b-form-group',[_c('h6',{staticClass:"text-primary required"},[_vm._v("Số cuốn")]),_c('validation-provider',{attrs:{"name":"book","rules":"required","mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
                  var validate = ref.validate;
return [_c('v-select',{attrs:{"taggable":"","label":"book","options":_vm.bookOptions},on:{"input":function($event){return _vm.onPriceChange(false)},"close":function () {
                    validate();
                  }},model:{value:(_vm.catalogueOrderDetail.totalBook),callback:function ($$v) {_vm.$set(_vm.catalogueOrderDetail, "totalBook", $$v)},expression:"catalogueOrderDetail.totalBook"}}),(errors.length > 0)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}])})],1)],1)],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-row',[_c('b-col',{attrs:{"sm":"4"}},[_c('b-form-group',[_c('h6',{staticClass:"text-primary required"},[_vm._v("Tổng diện tích")]),_c('b-form-input',{attrs:{"value":0,"disabled":""}})],1)],1),_c('b-col',{attrs:{"sm":"4"}},[_c('b-form-group',[_c('h6',{staticClass:"text-primary required"},[_vm._v("Đơn giá")]),_c('validation-provider',{directives:[{name:"number",rawName:"v-number"}],attrs:{"name":"printUnitPrice","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
                  var failedRules = ref.failedRules;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"trim":""},on:{"input":function($event){return _vm.onPriceChange(true)}},model:{value:(_vm.catalogueOrderDetail.pricePerUnit),callback:function ($$v) {_vm.$set(_vm.catalogueOrderDetail, "pricePerUnit", $$v)},expression:"catalogueOrderDetail.pricePerUnit"}}),(failedRules.hasOwnProperty('required'))?_c('small',{staticClass:"text-danger"},[_vm._v(" Vui lòng nhập ")]):_vm._e()]}}])})],1)],1),_c('b-col',{attrs:{"sm":"4"}},[_c('b-form-group',[_c('h6',{staticClass:"text-primary required"},[_vm._v(" "+_vm._s(_vm.$t('TotalPrice'))+" ")]),_c('validation-provider',{directives:[{name:"number",rawName:"v-number"}],attrs:{"name":"TotalPrice","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
                  var failedRules = ref.failedRules;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"trim":""},model:{value:(_vm.catalogueOrderDetail.totalPrice),callback:function ($$v) {_vm.$set(_vm.catalogueOrderDetail, "totalPrice", $$v)},expression:"catalogueOrderDetail.totalPrice"}}),(failedRules.hasOwnProperty('required'))?_c('small',{staticClass:"text-danger"},[_vm._v(" Vui lòng nhập ")]):_vm._e()]}}])})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }